(function () {
    require('../libs/overlay-navigation').init({
        toggleSelector: '#menu-toggle',
        targetSelector: '#menu'
    });

    require('../libs/hash-translator').init();

    $(document).on('hashnavigate', function(){
        if( $('body').hasClass('has-overlay-active') ) {
            $('#menu-toggle').trigger('click');
        }
    });
})();
