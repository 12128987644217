require('./vendor/console-stub');

require('../components/parallax.js/parallax.min');
require('../components/bootstrap/js/scrollspy');

require('./libs/bg-video');

require('./app/navigation');
require('./app/hero');
require('./app/testimonials');

$(function(){
    setTimeout(function(){
        $(window).trigger('resize').trigger('scroll');
    }, 0);
})
