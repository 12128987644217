(function(){
    var $ = require("./../../components/jquery/dist/jquery.js");

    require('../../components/slick-carousel/slick/slick.min.js');

    $('.testimonial-list').slick({
      dots: false,
      speed: 500,
      adaptiveHeight: true,
      fade: true
    });
})();
