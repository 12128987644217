(function(){
    var $target = $('.cover, .hero');

    function sinDegrees(angle) {
        return Math.sin(angle/180*Math.PI);
    }

    function setSize(){
        var angleHeight = ($(window).width() + 200) * sinDegrees(5);
        $target.css({
            'min-height' : $(window).height() + 10 + angleHeight,
            'padding-bottom' : angleHeight
        });
    }

    $(window).on('resize', setSize);

    setSize();
})();
